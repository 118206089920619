import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import type {
  ICompanyUpdateBody,
  ICompanyCreateBody,
  ICompanies,
  IUserCompanies,
} from './types'

export const useApiCompaniesByUserId = (userId: string) => {
  return useQuery<IUserCompanies>({
    queryKey: ['getCompanyByUserId', userId],
    queryFn: () => fetchAPI<IUserCompanies>(`/users/${userId}/companies`),
  })
}

export const useApiCompaniesById = (companyId: string) => {
  const { fetchCompanies } = useDivisions()
  const queryClient = useQueryClient()
  // GET Companies
  const query = useQuery<ICompanies>({
    queryKey: ['getCompanyWithId', companyId],
    queryFn: () => fetchAPI<ICompanies>(`/companies/${companyId}`),
  })

  const updateCompanyById = useMutation({
    mutationFn: (body: ICompanyUpdateBody) => {
      const url = `/companies/${companyId}`
      return fetchAPI(url, {
        method: 'PUT',
        body,
      })
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ['getCompanyWithId', companyId],
      })
      fetchCompanies()
    },
  })

  return {
    ...query,
    updateCompanyById,
  }
}

export const useApiCompany = () => {
  const { fetchCompanies } = useDivisions()

  const createCompany = useMutation({
    mutationFn: (body: ICompanyCreateBody) => {
      const url = `/companies`
      return fetchAPI(url, {
        method: 'POST',
        body,
      })
    },
    onSuccess: async (res: any) => {
      fetchCompanies()
      useRouter().push(`/division/${res.id}`)
    },
  })

  const createChildCompany = useMutation({
    mutationFn: (body: ICompanyCreateBody & { parentId: string }) => {
      const url = `/companies/${body.parentId}/companies`
      return fetchAPI(url, {
        method: 'POST',
        body,
      })
    },
    onSuccess: async () => {
      fetchCompanies()
    },
  })

  return {
    createCompany,
    createChildCompany,
  }
}
